import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {SEOService} from '@thebell/common/services/others/seo';
import {environment} from '../environments/environment';
import {SUBSCRIPTION_TOKEN_NAME} from './constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private seo: SEOService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.addMeta();

    this.route.queryParams.subscribe((params: any) => {
      const token = params[SUBSCRIPTION_TOKEN_NAME];
      if (token) {
        this.router.navigate([], {
          queryParams: {
            [SUBSCRIPTION_TOKEN_NAME]: null,
          },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  addMeta() {
    this.seo.push([
      {
        name: 'description',
        content:
          'Все важное для деловых людей на одном сайте. Закрытые встречи BellClub. ' +
          'Вдохновляющие истории предпринимателей «Русские норм».',
      },
      {property: 'og:locale', content: 'ru_RU'},
      {property: 'og:type', content: 'website'},
      {property: 'og:title', content: 'The Bell — деловые новости и аналитика'},
      {
        property: 'og:description',
        content:
          'Все важное для деловых людей на одном сайте. Закрытые встречи BellClub. ' +
          'Вдохновляющие истории предпринимателей «Русские норм».',
      },
      {property: 'og:url', content: `${environment.baseUrl}`},
      {property: 'og:site_name', content: 'The Bell'},
      {property: 'article:publisher', content: 'https://www.facebook.com/TheBell.io/'},
      {property: 'article:section', content: 'Новости'},
      {property: 'og:image', content: `${environment.baseUrl}wp-content/uploads/2018/03/thebell-cover.png`},
      {
        property: 'og:image:secure_url',
        content: `${environment.baseUrl}wp-content/uploads/2018/03/thebell-cover.png`,
      },
      {property: 'og:image:width', content: '1200'},
      {property: 'og:image:height', content: '630'},
      {property: 'twitter:card', content: 'summary_large_image'},
      {
        property: 'twitter:description',
        content:
          'Все важное для деловых людей на одном сайте. Закрытые встречи BellClub. ' +
          'Вдохновляющие истории предпринимателей «Русские норм».',
      },
      {
        property: 'twitter:title',
        content:
          'The Bell — Все важное для деловых людей на одном сайте. Закрытые встречи BellClub. ' +
          'Вдохновляющие истории предпринимателей «Русские норм».',
      },
      {property: 'twitter:site', content: '@thebell_io'},
      {property: 'twitter:image', content: `${environment.baseUrl}wp-content/uploads/2018/03/thebell-cover.png`},
      {property: 'twitter:creator', content: '@thebell_io'},
    ]);
  }
}
