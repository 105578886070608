/* eslint-disable @typescript-eslint/prefer-for-of */
import {isPlatformBrowser, Location} from '@angular/common';
import {Inject, Pipe, PipeTransform, PLATFORM_ID} from '@angular/core';
import {PostBannerService} from '@thebell/common/services/api/post-banner';
import {BehaviorSubject, interval, Subject} from 'rxjs';

const UTM_LINK_PARAMS = {
  utm_source: 'thebell.io',
  utm_medium: 'snippet',
  utm_campaign: 'regular',
};
const getUtmParams = (path) => ({...UTM_LINK_PARAMS, utm_content: path});

@Pipe({name: 'postDonateBanner'})
export class PostDonateBannerPipe implements PipeTransform {
  private $html = new BehaviorSubject('');
  private _domParser: DOMParser = null;

  private get domParser() {
    if (isPlatformBrowser(this.platformId) && !this._domParser) {
      this._domParser = new DOMParser();
    }
    return this._domParser;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    private bannerService: PostBannerService,
    private location: Location
  ) {}

  // FixMe (Move this logic into web-component in Textarena)
  private transformBannerContent(html: string) {
    if (isPlatformBrowser(this.platformId) && this.domParser) {
      const path = this.location.path().slice(1);
      const htmlDOM = this.domParser.parseFromString(decodeURI(html), 'text/html');
      const buttonLinkElements = htmlDOM.getElementsByClassName('textarena-btn');
      if (buttonLinkElements) {
        for (let i = 0; i < buttonLinkElements.length; i++) {
          const buttonLinkElement = buttonLinkElements[i];
          buttonLinkElement.setAttribute(
            'href',
            ((href) => {

              // Есть проблема с абсолютными ссылками, которые ведут на другой домен. Это из-за зеркал и скрипта для них.
              // поэтому приходится заменять домен на текущий, который может быть доменом зеркалам.
              // Получаем текущий домен
              const currentDomain = window.location.host;
              // Создаем объект URL из текущего URL
              const currentUrl = new URL(window.location.href);
              // Создаем объект URL из href, чтобы определить, является ли ссылка абсолютной или относительной
              const url = new URL(href, currentUrl);
              // Если ссылка абсолютная, заменяем домен на текущий домен
              if (url.host !== '') {
                url.host = currentDomain;
              }

              const params = getUtmParams(path);
              for (const param in params) {
                // eslint-disable-next-line no-prototype-builtins
                if (params.hasOwnProperty(param)) {
                  url.searchParams.append(param, params[param]);
                }
              }
              return decodeURI(url.toString());
            })(buttonLinkElement.getAttribute('href'))
          );
        }
        html = encodeURI(htmlDOM.body.innerHTML);
      }
    }
    return html;
  }

  async transformHtml(html: string) {
    if (isPlatformBrowser(this.platformId)) {
      const banner = await this.bannerService.actualBanner$.toPromise();
      //console.log('banner', JSON.stringify(banner))
      //console.log('banner.content', banner.content)
      if (banner && banner.content) {
        let _box;
        const content = this.transformBannerContent(banner.content);
        const re = /(<div data-banner="true"><\/div>)/g;
        while ((_box = re.exec(html)) !== null) {
          const startIndex = _box.index;
          const endIndex = _box.index + _box[1].length;
          html = `
            ${html.slice(0, startIndex)}
            ${decodeURI(content)}
            ${html.slice(endIndex, html.length)}`;
        }
      }
      this.$html.next(html);
    }
  }

  transform(html: string) {
    this.$html.next(html);
    this.transformHtml(html);
    return this.$html.asObservable();
  }
}
