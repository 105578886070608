/* eslint-disable */
export const ADDZEN = `function addZen(id, screenType, props = undefined) {
    // tslint:disable-next-line:only-arrow-functions no-shadowed-variable
    (function (w, d, n, id) {
        w[n] = w[n] || [];
        const orientation = props?.orientation
            ? props?.orientation
            : screenType === 'desktop'
                ? 'vertical'
                : 'horizontal';
        const size = props?.size ? props?.size : screenType === 'desktop' ? 's' : 'm';
        w[n].push(() => {
            // @ts-ignore
            w.YandexZen.renderWidget({
                // идентификатор партнера, должен быть получен в пункте 2
                clid: [9218, 9219],
                // cелектор элемента или сам элемент, куда надо вставить виджет
                container: '.widget-container-' + id,
                // вызывается при успешной вставке виджета
                successCallback() {
                    // console.log('виджет вставлен успешно');
                    const parentElem = d.getElementsByClassName('widget-container-' + id)[0];
                    parentElem.classList.add('widget-container')
                    if (d.location.pathname.lastIndexOf('/') === 0 && d.location.pathname.length > 1) {
                        const elem = d.createElement('div');
                        elem.innerText = 'Яндекс.Дзен';
                        elem.classList.add('header');
                        if (props) elem.classList.add('widget')

                        const widget = parentElem.firstChild;
                        parentElem.insertBefore(elem, widget);
                        parentElem.classList.add('ad-block');
                    }
                },
                // вызывается при неуспешной вставке виджета
                failCallback() {
                    console.warn('проблема при вставке виджета, отреагируйте на нее');
                },
                // визуальные настройки выбранные вами
                orientation,
                size,
            });
        });
        const oldScript = window.document.querySelector('#zenWidgetLoader');
        if (oldScript) {
            oldScript.remove();
        }
        const t = d.getElementsByTagName('script')[0];
        const s = d.createElement('script');
        s.id = 'zenWidgetLoader';
        s.src = '//zen.yandex.ru/widget-loader';
        s.async = true;
        t.parentNode.insertBefore(s, t);
    })(window, document, 'yandexZenAsyncCallbacks', id);
};`;

export const ADDRTB = `function yandexRtb(id) {
    // tslint:disable-next-line:only-arrow-functions
    (function (w, d, n, s, t) {
        w[n] = w[n] || [];
        // tslint:disable-next-line:only-arrow-functions
        w[n].push(function () {
            w.Ya.Context.AdvManager.render({
                blockId: id,
                renderTo: 'yandex_rtb_' + id,
                async: true,
            });
        });
        const oldScript = window.document.querySelector('#yandexRTBLoader');
        if (oldScript) {
            oldScript.remove();
        }
        t = d.getElementsByTagName('script')[0];
        s = d.createElement('script');
        s.id = 'yandexRTBLoader';
        s.type = 'text/javascript';
        s.src = '//an.yandex.ru/system/context.js';
        s.async = true;
        t.parentNode.insertBefore(s, t);
    })(this, this.document, 'yandexContextAsyncCallbacks');
};`;

export const ADDFOX = `function addAddFox(id, params, type) {
    const MOB1 = {
        pp: 'noj',
        ps: 'czji',
        p2: 'fwbj',
        puid1: '',
    };

    const MOB2 = {
        pp: 'nok',
        ps: 'czji',
        p2: 'fwbj',
        puid1: '',
        puid2: '',
        puid3: '',
    };

    const MOB3 = {
        pp: 'nol',
        ps: 'czji',
        p2: 'fwbj',
        puid1: '',
    };

    const SIDE1 = {
        pp: 'noj',
        ps: 'czji',
        p2: 'fvyf',
        puid1: '',
    };


    if (params === undefined) {
        switch (type) {
            case 'adfox_mob1':
                params = MOB1;
                break
            case 'adfox_mob2':
                params = MOB2;
                break
            case 'adfox_mob3':
                params = MOB3;
                break
            case 'adfox_side1':
                params = SIDE1
        }
    }
    const a = setInterval(() => {
        if (
            typeof window.Ya !== 'undefined' &&
            typeof window.Ya.adfoxCode !== 'undefined' &&
            typeof window.Ya.headerBidding !== 'undefined'
        ) {

            switch (type){
                case 'adfox_side1':
                    window.Ya.headerBidding.pushAdUnits([
                        {
                            code: 'ad-sidebar-' + id,
                            sizes: [[300, 600]],
                            bids: [
                                {
                                    bidder: "criteo",
                                    params: {
                                        placementId: 1337690
                                    }
                                },
                                {
                                    bidder: "myTarget",
                                    params: {
                                        placementId: 323164
                                    }
                                },
                                {
                                    bidder: "betweenDigital",
                                    params: {
                                        placementId: 2688856
                                    }
                                },
                                {
                                    bidder: "adriver",
                                    params: {
                                        placementId: "20:thebell_300x600"
                                    }
                                },
                                {
                                    bidder: "rtbhouse",
                                    params: {
                                        placementId: "ZevNJ2AlO10OqMWY9U2U"
                                    }
                                },
                                {
                                    bidder: "otm",
                                    params: {
                                        placementId: 980
                                    }
                                },
                                {
                                    bidder: "getintent",
                                    params: {
                                        placementId: "134_thebell.io_desktop_adfox_side1_300x600"
                                    }
                                },
                                {
                                    bidder: "buzzoola",
                                    params: {
                                        placementId: 296847
                                    }
                                },
                                {
                                    bidder: "relap",
                                    params: {
                                        placementId: "qlWqJOQkrLVPPUND"
                                    }
                                },
                                {
                                    bidder: "segmento",
                                    params: {
                                        placementId: 320
                                    }
                                }
                            ]
                        },
                    ]);
                    break;
                case 'adfox_mob1':
                    window.Ya.headerBidding.pushAdUnits([
                        {
                            code: 'ad-sidebar-' + id,
                            sizes: [[300, 250]],
                            bids: [
                                {
                                    bidder: "criteo",
                                    params: {
                                        placementId: 1573803
                                    }
                                },
                                {
                                    bidder: "myTarget",
                                    params: {
                                        placementId: 323160
                                    }
                                },
                                {
                                    bidder: "betweenDigital",
                                    params: {
                                        placementId: 2688854
                                    }
                                },
                                {
                                    bidder: "adriver",
                                    params: {
                                        placementId: "20:thebell_300x250mob_1"
                                    }
                                },
                                {
                                    bidder: "rtbhouse",
                                    params: {
                                        placementId: "tT6fgySztHjh23t7kDlz"
                                    }
                                },
                                {
                                    bidder: "otm",
                                    params: {
                                        placementId: 978
                                    }
                                },
                                {
                                    bidder: "getintent",
                                    params: {
                                        placementId: "134_thebell.io_mobile_adfox_mob1_300x250"
                                    }
                                },
                                {
                                    bidder: "buzzoola",
                                    params: {
                                        placementId: 296845
                                    }
                                },
                                {
                                    bidder: "relap",
                                    params: {
                                        placementId: "N8g3tna2AhvhgPz8"
                                    }
                                },
                                {
                                    bidder: "segmento",
                                    params: {
                                        placementId: 318
                                    }
                                }
                            ]
                        },
                    ]);
                    break
                case 'adfox_mob2':
                    window.Ya.headerBidding.pushAdUnits([
                        {
                            code: 'ad-sidebar-' + id,
                            sizes: [[300, 250]],
                            bids: [
                                {
                                    bidder: "criteo",
                                    params: {
                                        placementId: 1337685
                                    }
                                },
                                {
                                    bidder: "myTarget",
                                    params: {
                                        placementId: 859328
                                    }
                                },
                                {
                                    bidder: "betweenDigital",
                                    params: {
                                        placementId: 4011760
                                    }
                                },
                                {
                                    bidder: "getintent",
                                    params: {
                                        placementId: "134_thebell.io_mobile_adfox_mob2_300x250"
                                    }
                                },
                                {
                                    bidder: "buzzoola",
                                    params: {
                                        placementId: 1220820
                                    }
                                }
                            ]
                        },
                    ]);
                    break
                case 'adfox_mob3':
                    window.Ya.headerBidding.pushAdUnits([
                        {
                            code: 'ad-sidebar-' + id,
                            sizes: [[300, 250]],
                            bids: [
                                {
                                    bidder: "criteo",
                                    params: {
                                        placementId: 1337686
                                    }
                                },
                                {
                                    bidder: "myTarget",
                                    params: {
                                        placementId: 323162
                                    }
                                },
                                {
                                    bidder: "betweenDigital",
                                    params: {
                                        placementId: 2688855
                                    }
                                },
                                {
                                    bidder: "adriver",
                                    params: {
                                        placementId: "20:thebell_300x250mob_2"
                                    }
                                },
                                {
                                    bidder: "rtbhouse",
                                    params: {
                                        placementId: "8PTA13urbkfGQJUn2mjM"
                                    }
                                },
                                {
                                    bidder: "otm",
                                    params: {
                                        placementId: 979
                                    }
                                },
                                {
                                    bidder: "getintent",
                                    params: {
                                        placementId: "134_thebell.io_mobile_adfox_mob3_300x250"
                                    }
                                },
                                {
                                    bidder: "buzzoola",
                                    params: {
                                        placementId: 296846
                                    }
                                },
                                {
                                    bidder: "relap",
                                    params: {
                                        placementId: "JFlZ_90cbpRub29v"
                                    }
                                },
                                {
                                    bidder: "segmento",
                                    params: {
                                        placementId: 319
                                    }
                                }
                            ]
                        },
                    ]);
                    break
                case 'adfox_mid1':
                    window.Ya.headerBidding.pushAdUnits([
                        {
                            code: 'ad-sidebar-' + id,
                            sizes: [[300, 250]],
                            bids: [
                                {
                                    bidder: "criteo",
                                    params: {
                                        placementId: 1337687
                                    }
                                },
                                {
                                    bidder: "myTarget",
                                    params: {
                                        placementId: 859322
                                    }
                                },
                                {
                                    bidder: "betweenDigital",
                                    params: {
                                        placementId: 4011757
                                    }
                                },
                                {
                                    bidder: "otm",
                                    params: {
                                        placementId: 6760
                                    }
                                },
                                {
                                    bidder: "getintent",
                                    params: {
                                        placementId: "134_thebell.io_desktop_adfox_mid1_300x250"
                                    }
                                },
                                {
                                    bidder: "buzzoola",
                                    params: {
                                        placementId: 1220817
                                    }
                                },
                                {
                                    bidder: "segmento",
                                    params: {
                                        placementId: 321
                                    }
                                }
                            ]
                        },
                    ]);
                    break
                case 'adfox_mid2':
                    window.Ya.headerBidding.pushAdUnits([
                        {
                            code: 'ad-sidebar-' + id,
                            sizes: [[300, 250]],
                            bids: [
                                {
                                    bidder: "criteo",
                                    params: {
                                        placementId: 1337688
                                    }
                                },
                                {
                                    bidder: "myTarget",
                                    params: {
                                        placementId: 859324
                                    }
                                },
                                {
                                    bidder: "betweenDigital",
                                    params: {
                                        placementId: 4011758
                                    }
                                },
                                {
                                    bidder: "otm",
                                    params: {
                                        placementId: 6761
                                    }
                                },
                                {
                                    bidder: "getintent",
                                    params: {
                                        placementId: "134_thebell.io_desktop_adfox_mid2_300x250"
                                    }
                                },
                                {
                                    bidder: "buzzoola",
                                    params: {
                                        placementId: 1220818
                                    }
                                },
                                {
                                    bidder: "segmento",
                                    params: {
                                        placementId: 322
                                    }
                                }
                            ]
                        },
                    ]);
                    break
                case 'adfox_mid3':
                    window.Ya.headerBidding.pushAdUnits([
                        {
                            code: 'ad-sidebar-' + id,
                            sizes: [[300, 250]],
                            bids: [
                                {
                                    bidder: "criteo",
                                    params: {
                                        placementId: 1337688
                                    }
                                },
                                {
                                    bidder: "myTarget",
                                    params: {
                                        placementId: 859324
                                    }
                                },
                                {
                                    bidder: "betweenDigital",
                                    params: {
                                        placementId: 4011758
                                    }
                                },
                                {
                                    bidder: "otm",
                                    params: {
                                        placementId: 6761
                                    }
                                },
                                {
                                    bidder: "getintent",
                                    params: {
                                        placementId: "134_thebell.io_desktop_adfox_mid2_300x250"
                                    }
                                },
                                {
                                    bidder: "buzzoola",
                                    params: {
                                        placementId: 1220818
                                    }
                                },
                                {
                                    bidder: "segmento",
                                    params: {
                                        placementId: 322
                                    }
                                }
                            ]
                        },
                    ]);
            }
            window.yaContextCb.push(()=>{
              Ya.adfoxCode.create({
                  ownerId: 254948,
                  containerId: 'ad-sidebar-' + id,
                  params,
                  onRender: () => {
                      let elem = document.getElementById('ad-sidebar-' + id);
                      elem.classList.add('ad-sidebar');
                      elem.classList.add('ad-block');
                  },
               });
              })
            // console.log('clearInterval type:', type, 'id:', id);
            clearInterval(a);
        }
    }, 1000);
};`;

export const ADDSMI2 = `function addSmi2(adsBlock, smi2Id) {
    if (smi2Id === undefined) smi2Id = '93755';
    const smi2 = window.document.createElement('div');
    smi2.id = 'unit_' + smi2Id;
    smi2.classList.add('ad-block');
    const smi2Link = window.document.createElement('a');
    smi2Link.setAttribute('href', (document.location.protocol === 'https:' ? 'https:' : 'http:') + '//smi2.ru');
    smi2.appendChild(smi2Link);
    adsBlock.appendChild(smi2);
    const smi2Script = window.document.querySelector('#smi2Script');
    if (smi2Script) smi2Script.remove();
    const sc = window.document.createElement('script');
    sc.id = 'smi2Script';
    sc.type = 'text/javascript';
    sc.async = true;
    sc.src = '//smi2.ru/data/js/' + smi2Id + '.js';
    const s = window.document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(sc, s);
};`;
